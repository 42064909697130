import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    "data-test": "quick-dashboard-condition",
    style: _normalizeStyle({
      paddingLeft: '5px',
      borderLeft: '10px solid ' + _ctx.computeColorForCondition(_ctx.params.value),
    })
  }, _toDisplayString(_ctx.params.value), 5 /* TEXT, STYLE */))
}